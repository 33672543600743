<template lang="html">
  <div class="scanner-container">
    <video
      v-show="!isLoading"
      poster="data:image/gif,AAAA"
      ref="scanner"
    ></video>
  </div>
</template>

<script>
import { BrowserMultiFormatReader, Exception } from '@zxing/library';

export default {
  name: 'StreamBarcodeReader',
  data() {
    return {
      isLoading: true,
      codeReader: new BrowserMultiFormatReader(),
      isMediaStreamAPISupported:
        navigator &&
        navigator.mediaDevices &&
        'enumerateDevices' in navigator.mediaDevices,
      errors: {
        denied: 'ERROR: you need to grant camera access permission',
      },
    };
  },
  mounted() {
    if (!this.isMediaStreamAPISupported) {
      throw new Exception('Media Stream API is not supported');
    }

    this.getPermissions();

    this.$refs.scanner.oncanplay = () => {
      this.isLoading = false;
      this.$emit('loaded');
    };
  },
  beforeUnmount() {
    this.codeReader.reset();
  },
  methods: {
    async getPermissions() {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        this.start();
      } catch {
        this.$emit('error', this.errors.denied);
      }
    },
    start() {
      this.codeReader.decodeFromVideoDevice(
        undefined,
        this.$refs.scanner,
        (result) => {
          if (result) {
            this.$emit('decode', result.text);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scanner-container {
  position: relative;
}
</style>
