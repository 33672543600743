<template lang="html">
  <div class="desktop-dummy">
    <div v-if="globalSettings.desktopDummy.image" class="desktop-dummy__image">
      <img
        :src="globalSettings.desktopDummy.image.sizes['medium_large']"
        :alt="globalSettings.desktopDummy.image.alt"
      />
    </div>
    <div
      class="desktop-dummy__heading"
      v-if="globalSettings.desktopDummy.heading"
    >
      {{ globalSettings.desktopDummy.heading }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesktopDummy',
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.desktop-dummy {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;

  &__image {
    margin-bottom: 20px;
  }

  &__heading {
    @include tcl-h2;
  }
}
</style>
