import { createStore, createLogger } from 'vuex';
import global from '@/store/modules/global';
import routing from '@/store/modules/routing';
import page from '@/store/modules/page';
import cpt from '@/store/modules/cpt';
import cookies from '@/store/modules/cookies';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    global,
    routing,
    page,
    cpt,
    cookies,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
