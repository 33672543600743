<template>
  <div class="dummy-image">
    <svg
      width="82"
      height="82"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!--      <rect width="82" height="82" rx="5" fill="#C4C4C4" />-->
      <path
        d="M63.2018 13.12H19.3803C15.9229 13.12 13.1201 15.9228 13.1201 19.3802V63.2017C13.1201 66.6591 15.9229 69.4619 19.3803 69.4619H63.2018C66.6593 69.4619 69.4621 66.6591 69.4621 63.2017V19.3802C69.4621 15.9228 66.6593 13.12 63.2018 13.12Z"
        stroke="#E5E5E5"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.3358 35.0311C32.9289 35.0311 35.0309 32.929 35.0309 30.3359C35.0309 27.7428 32.9289 25.6407 30.3358 25.6407C27.7427 25.6407 25.6406 27.7428 25.6406 30.3359C25.6406 32.929 27.7427 35.0311 30.3358 35.0311Z"
        stroke="#E5E5E5"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.4621 50.6815L53.8115 35.031L19.3804 69.4622"
        stroke="#E5E5E5"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DummyImage',
};
</script>

<style lang="scss" scoped>
.dummy-image {
  background: #c4c4c4;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    max-width: 80%;
    max-height: 80%;
  }
}
</style>
