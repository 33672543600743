<template lang="html">
  <div class="screen123">
    <div class="screen123__counter">
      <svg
        id="svg"
        width="200"
        height="200"
        viewport="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          id="bar"
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          stroke="#FF9F1E"
          stroke-width="8px"
          stroke-dasharray="565.48"
          stroke-dashoffset="0"
          :style="`stroke-dashoffset: ${pct}px`"
        ></circle>
      </svg>
      <img src="@/assets/images/counter_background.png" alt="Counter" />
      <span>{{ counter }}</span>
    </div>
    <HeadingDescription
      v-if="screen123.heading || screen123.description"
      :initialData="{
        heading: screen123.heading,
        description: screen123.description,
        text_align: {
          value: 'left',
        },
        heading_type: {
          value: 'h1',
        },
      }"
    />
  </div>
</template>

<script>
import HeadingDescription from '@/sections/HeadingDescription';
export default {
  name: 'Screen123',
  components: { HeadingDescription },
  data() {
    return {
      counter: 3,
      pct: 565.48,
      counterInterval: null,
    };
  },
  computed: {
    screen123() {
      return this.$store.state.global.settings.screen123;
    },
  },
  methods: {
    animateCircle(val = 0) {
      const r = 90;
      const c = Math.PI * (r * 2);

      if (val < 0) {
        val = 0;
      }
      if (val > 100) {
        val = 100;
      }

      this.pct = ((100 - val) / 100) * c;
    },
  },
  mounted() {
    setTimeout(() => {
      this.animateCircle(100);
      this.counterInterval = setInterval(() => {
        this.counter--;
        if (this.counter === 0) {
          clearInterval(this.counterInterval);
          sessionStorage.setItem('screen123', 'shown');
          this.$emit('hide');
        }
      }, 1000);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.screen123 {
  position: fixed;
  background: $backgroundGradient;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 $containerPadding;
  box-sizing: border-box;

  &__counter {
    width: max-content;
    position: relative;
    margin: 50px auto 50px;

    circle {
      transition: stroke-dashoffset 3s linear;
    }

    img {
      height: 25vh;
      width: auto;
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.3) rotate(-90deg);
    }

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: 45px;
      line-height: 54px;
      color: $green;
    }
  }
}
</style>
