<template lang="html">
  <div class="footer">
    <span
      class="footer__link"
      v-for="item in globalSettings.bottomLinks"
      :key="item.name"
      @click="clickFooterLink(item)"
    >
      {{ item.name }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    clickFooterLink(item) {
      this.$store.commit('routing/setCurrentRoute', item.page['post_name']);
      this.$emit('click-footer-link');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.footer {
  border-top: 1px solid $cream;
  height: $headerHeight;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0 $containerPadding;

  &__link {
    @include tcl-big-text;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
  }
}
</style>
