import SiteSettings from '@/services/SiteSettings';

const state = {
  settings: {
    headerLogo: {},
    headerMenuHeading: '',
    headerMenuLinks: [],
    headerLinks: [],
    social: {},
    cookieSettingsButton: '',
    headerMenuBottomText: '',
    bottomLinks: [],
    modalQrHelp: {},
    modalFailureQrCode: {},
    screen123: {
      heading: '',
      description: '',
    },
    page404: {
      logo: null,
      heading: '',
      description: '',
      button: '',
      audio: null,
    },
    rotateScreen: {
      text: '',
    },
  },
  siteMap: {},
  isMobile: undefined,
  listDrinkQr: [],
};

// getters
const getters = {};

// actions
const actions = {
  fetchSettings({ commit }) {
    return new Promise((resolve, reject) => {
      SiteSettings.getAllSettings().then(
        (response) => {
          commit('setSettings', response.data);
          commit('setSiteMap', response.data['site_map_group']);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  fetchListDrinkQr({ commit }) {
    return new Promise((resolve, reject) => {
      SiteSettings.getListDrinkQr().then(
        (response) => {
          commit('setListDrinkQr', response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

// mutations
const mutations = {
  setSettings(state, payload) {
    state.settings.headerLogo = payload['header_logo'];
    state.settings.headerMenuHeading = payload['header_menu_heading'];
    state.settings.headerMenuLinks = payload['header_menu_links'];
    state.settings.headerLinks = payload['header_links'];
    state.settings.social = payload['social'];
    state.settings.cookieSettingsButton = payload['cookie_settings_button'];
    state.settings.headerMenuBottomText = payload['header_menu_bottom_text'];
    state.settings.bottomLinks = payload['bottom_links'];
    state.settings.desktopDummy = payload['desktop_dummy'];
    state.settings.modalQrHelp = payload['modal_qr_help'];
    state.settings.modalFailureQrCode = payload['modal_failure_qr_code'];
    state.settings.screen123.heading = payload['screen123__heading'];
    state.settings.screen123.description = payload['screen123__description'];
    state.settings.page404.logo = payload['page404_logo'];
    state.settings.page404.heading = payload['page404_heading'];
    state.settings.page404.description = payload['page404_description'];
    state.settings.page404.button = payload['page404_button'];
    state.settings.page404.audio = payload['page404_audio'];
    if (payload['rotate_screen'] && ['text']) {
      state.settings.rotateScreen.text = payload['rotate_screen']['text'];
    }
  },
  setListDrinkQr(state, payload) {
    state.listDrinkQr = payload;
  },
  setSiteMap(state, payload) {
    for (const key in payload) {
      state.siteMap[key] = payload[key]['post_name'];
    }
  },
  setIsMobile(state, payload) {
    state.isMobile = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
