import http from '../http';

class CustomPostTypes {
  getPostType(postType) {
    return http.get(`/cpt/${postType}`);
  }
  getSinglePost(postType, id) {
    return http.get(`/cpt/${postType}/${id}`);
  }
}

export default new CustomPostTypes();
