<template>
  <div class="menu-links" v-if="!showQrCodeCamera">
    <div class="menu-links__links">
      <div
        class="menu-links__link"
        v-for="item in globalSettings.headerMenuLinks"
        :key="item.name"
      >
        <div class="menu-links__button" @click="clickLink(item)">
          <span>{{ item.name }}</span>
        </div>
        <img
          v-if="item['qr_code'] && !checkQrCode(item['qr_code'])"
          src="@/assets/images/lock.svg"
          alt="Lock"
        />
      </div>
    </div>
  </div>
  <ModalQrCheck :show="showModal" @close-modal="closeModal" />
  <ModalQrFailure
    :show="modalQrFailureIsShow"
    @close-modal="closeModalQrFailure"
  />
  <QrCodeCamera
    :show="showQrCodeCamera"
    @read-code="readCodeFromCamera"
    @close-modal="closeQrModal"
  />
</template>

<script>
import QrCodeCamera from '@/components/QrCodeCamera';
import ModalQrCheck from '@/components/modals/ModalQrCheck';
import ModalQrFailure from '@/components/modals/ModalQrFailure';

export default {
  name: 'MenuLinks',
  components: {
    ModalQrFailure,
    ModalQrCheck,
    QrCodeCamera,
  },
  emits: ['click-menu-link'],
  data() {
    return {
      showModal: false,
      showQrCodeCamera: false,
      modalQrFailureIsShow: false,
      selectedQr: '',
      selectedPage: '',
    };
  },
  computed: {
    appData() {
      return this.$store.state.cookies.appData;
    },
    globalSettings() {
      return this.$store.state.global.settings;
    },
    readQrCodes() {
      return this.$store.state.cookies.appData.readQrCodes;
    },
  },
  methods: {
    clickLink(link) {
      if (link['qr_code'] && !this.checkQrCode(link['qr_code'])) {
        this.selectedQr = link['qr_code'];
        this.selectedPage = link.page['post_name'];
        this.openModal();
      } else {
        this.$store.commit('routing/setCurrentRoute', link.page['post_name']);
        this.$emit('click-menu-link');
      }
    },
    checkQrCode(code) {
      if (!this.readQrCodes || !this.readQrCodes.length) return false;
      return this.readQrCodes.indexOf(code) !== -1;
    },
    setCode(id) {
      const codes = [...this.readQrCodes];
      codes.push(id);

      this.$store.commit('cookies/saveAppData', {
        key: 'readQrCodes',
        value: codes,
      });
    },
    openModal() {
      if (this.appData.modalQrHelpIsShow) {
        this.showQrCodeCamera = true;
      } else {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
      this.showQrCodeCamera = true;
      this.$store.commit('cookies/saveAppData', {
        key: 'modalQrHelpIsShow',
        value: true,
      });
    },
    closeModalQrFailure() {
      this.modalQrFailureIsShow = false;
    },
    closeQrModal() {
      this.showQrCodeCamera = false;
    },
    readCodeFromCamera(e) {
      if (e === this.selectedQr) {
        this.setCode(e);
        this.showQrCodeCamera = false;
        this.$store.commit('routing/setCurrentRoute', this.selectedPage);
      } else {
        if (this.modalQrFailureIsShow) return;
        this.closeQrModal();
        this.modalQrFailureIsShow = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.menu-links {
  &__links {
    width: max-content;
  }

  &__link {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 30px;
      height: auto;
      fill: $orange;
      margin-left: 40px;
    }
  }

  &__button {
    @include tcl-side-line(120%);
    @include tcl-h2;

    span {
      position: relative;
      z-index: 1;
      text-transform: uppercase;
    }
  }
}
</style>
