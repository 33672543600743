import http from '../http';

class SiteSettings {
  getAllSettings() {
    return http.get('/settings/site');
  }
  getListDrinkQr() {
    return http.get(`/drink-qr/list`);
  }
  // getAllRoutes() {
  //   return http.get('/router/list');
  // }
}

export default new SiteSettings();
