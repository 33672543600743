import { createApp } from 'vue';
import { createGtm } from 'vue-gtm';
import App from './App.vue';
import router from './router';
import store from './store';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import './registerServiceWorker';

Promise.all([
  store.dispatch('global/fetchSettings'),
  store.dispatch('global/fetchListDrinkQr'),
]).then(() => {
  const app = createApp(App);
  app.use(store);
  app.use(router);
  app.use(VuePlyr, {
    plyr: {},
  });
  app.use(
    createGtm({
      id: 'GTM-N9J3M27', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    })
  );

  app.mount('#app');
});
