<template lang="html">
  <svg
    class="back"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="back"
    v-if="buttonBackIsShow"
  >
    <path
      d="M7.00001 37L31 37C34.3137 37 37 34.3137 37 31L37 7C37 3.68629 34.3137 0.999996 31 0.999997L7 0.999999C3.68629 0.999999 1 3.68629 1 7L1 31C1 34.3137 3.6863 37 7.00001 37Z"
      stroke="#DD5A12"
      stroke-width="2"
    />
    <path
      d="M14.2929 18.2929C13.9024 18.6834 13.9024 19.3166 14.2929 19.7071L20.6569 26.0711C21.0474 26.4616 21.6805 26.4616 22.0711 26.0711C22.4616 25.6805 22.4616 25.0474 22.0711 24.6569L16.4142 19L22.0711 13.3431C22.4616 12.9526 22.4616 12.3195 22.0711 11.9289C21.6805 11.5384 21.0474 11.5384 20.6569 11.9289L14.2929 18.2929ZM15.5 18L15 18L15 20L15.5 20L15.5 18Z"
      fill="#DD5A12"
    />
  </svg>
  <transition name="page-fade" mode="out-in">
    <SinglePost
      v-if="routing.currentRoute === 'single-post'"
      :postType="routing.singlePostData.postType"
      :postId="routing.singlePostData.postId"
    />
    <Sections
      v-else-if="$store.state.page.sections.length"
      :sections="$store.state.page.sections"
    />
  </transition>
</template>

<script>
import Sections from '@/sections/Sections';
import SinglePost from '@/views/SinglePost';

export default {
  name: 'Page',
  components: {
    SinglePost,
    Sections,
  },
  data() {
    return {
      routeName: 'home',
    };
  },
  computed: {
    routing() {
      return this.$store.state.routing;
    },
    appData() {
      return this.$store.state.cookies.appData;
    },
    siteMap() {
      return this.$store.state.global.siteMap;
    },
    globalSettings() {
      return this.$store.state.global.settings;
    },
    listDrinkQr() {
      return this.$store.state.global.listDrinkQr;
    },
    readQrCodes() {
      return this.$store.state.cookies.appData.readQrCodes;
    },
    headerIsShow() {
      return this.appData.welcome && this.appData.cookiesAll;
    },
    buttonBackIsShow() {
      return (
        [
          this.siteMap.datenschutz,
          this.siteMap.impressum,
          this.siteMap.cookies_details,
        ].includes(this.routing.currentRoute) && !this.headerIsShow
      );
    },
  },
  watch: {
    'routing.currentRoute'() {
      this.goToRoute();
    },
  },
  created() {
    for (const pageSlug in this.$route.query) {
      // Pages
      if (this.$route.query[pageSlug] && this.siteMap[pageSlug]) {
        const curentPage = this.globalSettings.headerMenuLinks.find(
          ({ page }) => this.siteMap[pageSlug] === page['post_name']
        );
        if (this.$route.query[pageSlug] === curentPage['qr_code']) {
          this.$store.commit(
            'routing/setCurrentRoute',
            curentPage.page['post_name']
          );

          // Set code  to storage
          const codes = [...this.readQrCodes];
          codes.push(curentPage['qr_code']);
          this.$store.commit('cookies/saveAppData', {
            key: 'readQrCodes',
            value: codes,
          });

          // Remove query params
          this.$router.replace({ query: null });
          return false;
        }
      }
      // Drink
      if (pageSlug === 'drink') {
        const curentDrink = this.listDrinkQr.find(
          ({ qr_code }) => qr_code && qr_code === this.$route.query[pageSlug]
        );

        if (curentDrink) {
          // Set code  to storage
          const codes = [...this.readQrCodes];
          codes.push(curentDrink['qr_code']);
          this.$store.commit('cookies/saveAppData', {
            key: 'readQrCodes',
            value: codes,
          });

          // Go To Drink
          this.$store.commit('routing/setCurrentRoute', 'single-post');
          this.$store.commit('routing/setSinglePostData', {
            postType: 'drinks',
            postId: String(curentDrink.id),
          });

          // Remove query params
          this.$router.replace({ query: null });
          return false;
        }
      }
    }

    if (!this.appData.welcome) {
      this.routeName = this.siteMap.welcome;
    } else if (!this.appData.cookiesAll) {
      this.routeName = this.siteMap.cookies;
    } else if (sessionStorage.getItem('routeState')) {
      const routeState = JSON.parse(sessionStorage.getItem('routeState'));
      this.routeName = routeState.currentRoute;
      if (this.routeName === 'single-post') {
        this.$store.commit(
          'routing/setSinglePostData',
          routeState.singlePostData
        );
      }

      sessionStorage.removeItem('routeState');
    } else {
      this.routeName = this.siteMap.home;
    }

    this.$store.commit('routing/setCurrentRoute', this.routeName);
    this.goToRoute();
  },
  methods: {
    goToRoute() {
      this.$store.commit('page/setSections', []);
      if (!['single-post'].includes(this.routing.currentRoute)) {
        this.$store.dispatch('page/fetchPageData', this.routing.currentRoute);
      }
    },
    back() {
      let routeName;
      if (!this.appData.welcome) {
        routeName = this.siteMap.welcome;
      } else if (!this.appData.cookiesAll) {
        routeName = this.siteMap.cookies;
      }
      this.$store.commit('page/setSections', []);
      this.$store.commit('routing/setCurrentRoute', routeName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.back {
  position: absolute;
  width: 34px;
  height: 34px;
  left: $containerPadding;
  top: 10px;
  z-index: 11;
}
</style>
