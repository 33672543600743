<template lang="html">
  <div
    class="heading-description"
    :class="`heading-description--${initialData['text_align']['value']}`"
  >
    <div class="heading-description__heading">
      <Heading
        v-if="initialData.heading"
        :heading="initialData.heading"
        :headingType="initialData['heading_type']['value']"
      />
    </div>
    <div
      class="heading-description__description"
      v-if="initialData.description"
      v-html="initialData.description"
    ></div>
  </div>
</template>

<script>
import Heading from '@/components/Heading';
export default {
  name: 'HeadingDescription',
  components: { Heading },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.heading-description {
  &__description {
    @include tcl-big-text;
    margin-top: 20px;

    :deep(*) {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &--right {
    * {
      text-align: right;
    }
    :deep(.heading) {
      &:before {
        right: #{-$containerPadding};
        left: unset;
      }
    }
  }
}
</style>
