<template lang="html">
  <transition name="show-header">
    <div class="header" v-if="headerIsShow">
      <div class="header__container">
        <button
          class="hamburger hamburger--spring header__hamburger"
          type="button"
          :class="{ 'is-active': hamburgerIsActive }"
          @click="hamburgerIsActive = !hamburgerIsActive"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <span
          class="header__logo"
          v-if="globalSettings.headerLogo.id"
          @click="clickByLogo"
        >
          <img
            :src="globalSettings.headerLogo['sizes']['medium']"
            :alt="globalSettings.headerLogo['alt']"
          />
        </span>
        <div class="header__languages">
          <span @click="changeLanguage('de')" :class="{ active: lang === 'de' }"
            >DE</span
          >
          <span class="header__languages-separator">|</span>
          <span @click="changeLanguage('en')" :class="{ active: lang === 'en' }"
            >EN</span
          >
        </div>
      </div>
    </div>
  </transition>
  <transition name="page-fade" mode="out-in">
    <HeaderDropdown
      :globalData="globalSettings"
      v-if="hamburgerIsActive"
      v-click-outside="vcoConfig"
      @click-menu-link="closeDropdown"
      @click-footer-link="closeDropdown"
      :buttonAddToHomeIsShow="buttonAddToHomeIsShow"
      :deferredPrompt="deferredPrompt"
    />
  </transition>
</template>

<script>
import HeaderDropdown from '@/components/HeaderDropdown';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'Header',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { HeaderDropdown },
  props: {
    headerIsShow: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      hamburgerIsActive: false,
      vcoConfig: {
        handler: this.onClickOutside,
        events: ['touchend', 'click'],
      },
      deferredPrompt: null,
      buttonAddToHomeIsShow: false,
    };
  },
  watch: {
    '$route.name'() {
      this.closeDropdown();
    },
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    siteMap() {
      return this.$store.state.global.siteMap;
    },
    lang() {
      return localStorage.getItem('local') || 'de';
    },
  },
  mounted() {
    this.captureEvent();
  },
  methods: {
    onClickOutside(event) {
      const clickPath = event.composedPath();
      const clickedCalendar = clickPath.some((el) => {
        if (el && el.classList) return el.classList.contains('hamburger');
      });
      if (clickedCalendar) return false;

      this.closeDropdown();
    },
    closeDropdown() {
      this.hamburgerIsActive = false;
    },
    changeLanguage(lang) {
      localStorage.setItem('local', lang);
      sessionStorage.setItem(
        'routeState',
        JSON.stringify(this.$store.state.routing)
      );
      window.location.reload();
    },
    clickByLogo() {
      this.$router.push({ name: 'home' });
      this.$store.commit('routing/setCurrentRoute', this.siteMap.home);
    },
    captureEvent() {
      let installPrompt;
      window.addEventListener('beforeinstallprompt', (e) => {
        console.log('event', e);
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        // this.deferredPrompt = e;
        installPrompt = e;
        this.buttonAddToHomeIsShow = true;
      });

      this.deferredPrompt = () => {
        this.buttonAddToHomeIsShow = false;
        installPrompt.prompt();
        installPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Accepted');
          } else {
            console.log('Denied');
          }
          installPrompt = null;
        });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/styles/hamburgers.min.css';

.header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  &__container {
    padding: 0 $containerSmallPadding;
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    height: $headerHeight;
  }

  &__hamburger {
    padding: 0;
    display: flex;
    align-items: center;
  }

  &__hamburger .hamburger-inner,
  &__hamburger .hamburger-inner:after,
  &__hamburger .hamburger-inner:before,
  &__hamburger.is-active .hamburger-inner,
  &__hamburger.is-active .hamburger-inner:after,
  &__hamburger.is-active .hamburger-inner:before {
    background-color: $cream;
  }

  &__languages {
    font-size: 20px;
    &-separator {
      padding: 0 5px;
    }
    .active {
      color: $orange;
    }
  }

  &__logo {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
