<template lang="html">
  <transition name="page-fade" mode="out-in">
    <div class="sections" v-if="sections.length">
      <SectionsItem
        :section="section"
        v-for="section in sections"
        :key="section.unique_id"
      />
    </div>
  </transition>
</template>

<script>
import SectionsItem from '@/sections/SectionsItem.vue';

export default {
  name: 'Sections',
  components: {
    SectionsItem,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sections {
  position: relative;
  //display: flex;
  //flex-direction: column;
  padding-top: 50px;
  box-sizing: border-box;
}
</style>
