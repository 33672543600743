<template lang="html">
  <div class="single-post">
    <transition name="page-fade" mode="out-in">
      <div class="single-post__hero" v-if="postData.id">
        <svg
          class="single-post__back"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="backFromSinglePage"
        >
          <path
            d="M7.00001 37L31 37C34.3137 37 37 34.3137 37 31L37 7C37 3.68629 34.3137 0.999996 31 0.999997L7 0.999999C3.68629 0.999999 1 3.68629 1 7L1 31C1 34.3137 3.6863 37 7.00001 37Z"
            stroke="#DD5A12"
            stroke-width="2"
          />
          <path
            d="M14.2929 18.2929C13.9024 18.6834 13.9024 19.3166 14.2929 19.7071L20.6569 26.0711C21.0474 26.4616 21.6805 26.4616 22.0711 26.0711C22.4616 25.6805 22.4616 25.0474 22.0711 24.6569L16.4142 19L22.0711 13.3431C22.4616 12.9526 22.4616 12.3195 22.0711 11.9289C21.6805 11.5384 21.0474 11.5384 20.6569 11.9289L14.2929 18.2929ZM15.5 18L15 18L15 20L15.5 20L15.5 18Z"
            fill="#DD5A12"
          />
        </svg>
        <img
          v-if="postData.full_image"
          :src="postData.full_image.sizes['medium_large']"
          :alt="postData.full_image.alt"
          :style="heroStyles"
        />
        <div :style="heroStyles" class="single-post__hero-dummy" v-else>
          <DummyImage />
        </div>
      </div>
    </transition>
    <Sections
      v-if="postData['page_sections']"
      :sections="postData['page_sections']"
    />
  </div>
</template>

<script>
import Sections from '@/sections/Sections';
import DummyImage from '@/components/DummyImage';

export default {
  name: 'SinglePost',
  components: { DummyImage, Sections },
  props: {
    postType: {
      type: String,
      required: true,
    },
    postId: {
      type: String,
      required: true,
    },
  },
  computed: {
    postData() {
      switch (this.postType) {
        case 'festivals':
          return this.$store.state.cpt.singleFestival;
        case 'drinks':
          return this.$store.state.cpt.singleDrink;
        default:
          return {};
      }
    },
    heroStyles() {
      return {
        height: window.innerHeight - 60 + 'px',
      };
    },
    routing() {
      return this.$store.state.routing;
    },
  },
  created() {
    switch (this.postType) {
      case 'festivals':
        this.$store.dispatch('cpt/fetchSingleFestival', this.postId);
        break;
      case 'drinks':
        this.$store.dispatch('cpt/fetchSingleDrink', this.postId);
        break;
      default:
        console.log(`Not found this custom post`);
    }
  },
  beforeUnmount() {
    switch (this.postType) {
      case 'festivals':
        this.$store.commit('cpt/setSingleFestival', {});
        break;
      case 'drinks':
        this.$store.commit('cpt/setSingleDrink', {});
        break;
      default:
        console.log(`Not found this custom post`);
    }
  },
  methods: {
    backFromSinglePage() {
      const page = this.routing.singlePostData.postType;
      this.$store.commit('routing/setCurrentRoute', page);
      this.$store.commit('routing/setSinglePostData', {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.single-post {
  &__hero {
    margin: 0 #{-$containerPadding};
    position: relative;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__back {
    position: absolute;
    width: 34px;
    height: 34px;
    left: 20px;
    top: 20px;
  }
}
</style>
