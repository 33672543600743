<template>
  <DesktopDummy v-if="!isMobile" />
  <template v-else>
    <RotateScreen v-if="isHorizontal" />
    <div
      class="app-container"
      :class="{ 'app-container--header-showed': headerIsShow }"
    >
      <transition name="page-fade" mode="out-in">
        <Screen123 v-if="screen123" @hide="screen123IsHide = true" />
      </transition>

      <Header :headerIsShow="headerIsShow && !screen123" />
      <div class="app-container__content" v-if="!screen123">
        <router-view />
        <Footer />
      </div>
    </div>
  </template>
</template>

<script>
import Header from '@/sections/Header';
import Footer from '@/components/Footer';
import DesktopDummy from '@/components/DesktopDummy';
import Screen123 from '@/sections/Screen123';
import RotateScreen from '@/components/RotateScreen';

export default {
  components: { RotateScreen, Screen123, DesktopDummy, Footer, Header },
  data() {
    return {
      protectedPasswordIsActive: false,
      screen123IsHide: false,
      isHorizontal: false,
    };
  },
  computed: {
    appData() {
      return this.$store.state.cookies.appData;
    },
    headerIsShow() {
      return this.appData.welcome && this.appData.cookiesAll;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
    routing() {
      return this.$store.state.routing;
    },
    screen123IsShow() {
      const shown = sessionStorage.getItem('screen123', true);
      return shown && shown === 'shown';
    },
    screen123() {
      return (
        this.appData.welcome &&
        this.appData.cookiesAll &&
        !this.screen123IsShow &&
        !this.screen123IsHide
      );
    },
  },
  created() {
    if (localStorage.getItem('localtimezone') === '2') {
      this.protectedPasswordIsActive = true;
    }
    this.$store.commit('cookies/loadAppData');

    // Check Screen Size
    this.handleIsMobile();
    window.addEventListener('resize', this.handleIsMobile);

    // Check Orientation
    this.handleOrientation();
    window.addEventListener('orientationchange', this.handleOrientation);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleIsMobile);
    window.removeEventListener('orientationchange', this.handleOrientation);
  },
  methods: {
    hideProtectedPassword() {
      this.protectedPasswordIsActive = true;
    },
    handleIsMobile() {
      this.$store.commit('global/setIsMobile', window.innerWidth < 768);
    },
    handleOrientation() {
      this.isHorizontal = screen.orientation.angle !== 0 || false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/reset';
@import '~@/styles/variables';
@import '~@/styles/animations';

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  color: $cream;
}

html,
body {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: $backgroundGradient;
  overscroll-behavior: contain;
  position: fixed;
  overflow: hidden;
}

body {
  font-family: $font1;
}

#app {
  @include tcl-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $cream;
  height: 100%;
  overflow: auto;
}

.app-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: padding-top 0.4s ease;

  &--header-showed {
    padding-top: $headerHeight;
  }

  &__content {
    width: 100%;
    padding: 0 $containerPadding;
    margin: 0 auto;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    & > .footer {
      margin: auto #{-$containerPadding} 0;
      min-height: $headerHeight;
    }
  }
}
</style>
