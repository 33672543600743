<template lang="html">
  <div class="header-dropdown">
    <div class="header-dropdown__container">
      <div class="header-dropdown__heading" v-if="globalData.headerMenuHeading">
        <HeadingDescription :heading="globalData.headerMenuHeading" />
      </div>
      <div class="header-dropdown__menu">
        <MenuLinks @click-menu-link="$emit('click-menu-link')" />
      </div>
      <div class="header-dropdown__links">
        <a
          class="header-dropdown__link"
          v-for="item in globalData.headerLinks"
          :key="item.link.title"
          :href="item.link.url"
          :target="item.link.target"
        >
          {{ item.link.title }}
        </a>
      </div>
      <div class="header-dropdown__buttons">
        <button
          v-if="buttonAddToHomeIsShow"
          class="header-dropdown__full-screen"
          @click="deferredPrompt()"
        >
          FULLSCREEN VERSION
        </button>
        <button
          class="header-dropdown__cookie-button"
          v-if="globalData.cookieSettingsButton"
        >
          {{ globalData.cookieSettingsButton }}
        </button>
      </div>
      <div class="header-dropdown__social">
        <a
          class="header-dropdown__social-link"
          v-for="(socialItem, key) in globalData.social"
          :key="key"
          :href="socialItem.url"
        >
          <img
            v-if="key === 'instagram'"
            src="@/assets/images/instagram.png"
            alt="Instagram"
          />
          <img
            v-else-if="key === 'facebook'"
            src="@/assets/images/facebook.png"
            alt="Facebook"
          />
          <img
            v-else-if="key === 'linkedin'"
            src="@/assets/images/linkedin.png"
            alt="LinkedIn"
          />
        </a>
      </div>
      <div
        class="header-dropdown__bottom-text"
        v-if="globalData.headerMenuBottomText"
        v-html="globalData.headerMenuBottomText"
      ></div>
    </div>
    <Footer @click-footer-link="$emit('click-footer-link')" />
  </div>
</template>

<script>
import HeadingDescription from '@/components/Heading';
import MenuLinks from '@/components/MenuLinks';
import Footer from '@/components/Footer';
export default {
  name: 'HeaderDropdown',
  components: { Footer, MenuLinks, HeadingDescription },
  props: ['globalData', 'buttonAddToHomeIsShow', 'deferredPrompt'],
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header-dropdown {
  position: absolute;
  left: 0;
  top: $headerHeight;
  width: 100%;
  height: calc(100% - #{$headerHeight});
  background: $backgroundGradient;
  z-index: 10;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;

  &__heading {
    margin-bottom: $sectionMediumMargin;
  }

  &__container {
    padding: 70px $containerPadding;
  }

  &__menu {
    position: relative;
    padding-bottom: $sectionMediumMargin;

    &:after {
      position: absolute;
      content: '';
      left: -$containerPadding;
      bottom: 0;
      height: 4px;
      background-color: $orange;
      width: 80%;
    }
  }

  &__links {
    padding-top: 40px;
  }

  &__link {
    @include tcl-h3;
    display: block;
    width: max-content;
    color: $cream;
    text-decoration: none;
    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    &-link {
      width: 50px;
      margin: 0 10px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__buttons {
    margin-top: 60px;
  }

  &__cookie-button {
    @include tcl-border-button;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 237px;
  }

  &__full-screen {
    @include tcl-button;
    margin-top: 20px;
    width: 237px;
    margin-left: auto;
    margin-right: auto;
    height: 32px;
  }

  &__bottom-text {
    margin-top: 40px;
  }
}
</style>
