const state = {
  currentRoute: '',
  singlePostData: {},
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setCurrentRoute(state, payload) {
    state.currentRoute = payload;
  },
  setSinglePostData(state, payload) {
    state.singlePostData = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
