<template lang="html">
  <div class="rotate-screen">
    <div class="rotate-screen__text" v-if="rotateScreen.text">
      {{ rotateScreen.text }}
    </div>
    <div class="rotate-screen__icon">
      <svg
        class="styles_rotate-placeholder__icon__3CFMj"
        height="512pt"
        viewBox="0 0 512 512.001"
        width="512pt"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m404.410156 255.78125 26.003906 26c4.027344 4.027344 10.574219 4.027344 14.601563 0l26.003906-26c6.488281-6.492188 1.886719-17.628906-7.304687-17.628906h-18.640625c-3.5-92.816406-78.332031-167.679688-171.136719-171.222656v-18.644532c0-9.175781-11.128906-13.800781-17.628906-7.304687l-26 26.003906c-4.027344 4.023437-4.027344 10.578125 0 14.605469l26 25.996094c6.484375 6.488281 17.628906 1.910156 17.628906-7.300782v-18.34375c84.53125 3.527344 152.644531 71.667969 156.125 156.210938h-18.347656c-9.191406 0-13.792969 11.140625-7.304688 17.628906zm-145.476562-166.78125-14.714844-14.714844 14.714844-14.714844zm193.496094 164.152344-14.714844 14.71875-14.714844-14.71875zm0 0"
        ></path>
        <path
          d="m494.898438 307.234375h-290.132813v-98.117187c0-4.144532-3.359375-7.5-7.5-7.5-4.144531 0-7.5 3.355468-7.5 7.5v98.117187h-43.894531c-9.429688 0-17.101563 7.671875-17.101563 17.101563v43.894531h-111.667969c-1.15625 0-2.101562-.941407-2.101562-2.097657v-349.03125c0-1.160156.945312-2.101562 2.101562-2.101562h170.5625c1.15625 0 2.101563.941406 2.101563 2.101562v157.015626c0 4.140624 3.355469 7.5 7.5 7.5s7.5-3.359376 7.5-7.5v-157.015626c0-9.429687-7.671875-17.101562-17.101563-17.101562h-170.5625c-9.429687 0-17.101562 7.671875-17.101562 17.101562v349.03125c0 9.429688 7.671875 17.101563 17.101562 17.101563h111.667969v111.664063c0 9.429687 7.671875 17.101562 17.101563 17.101562h155.195312c4.140625 0 7.5-3.355469 7.5-7.5s-3.359375-7.5-7.5-7.5h-155.195312c-1.160156 0-2.101563-.941406-2.101563-2.101562v-111.667969h43.894531c9.429688 0 17.101563-7.667969 17.101563-17.101563v-43.894531h290.132813c1.15625 0 2.101562.941406 2.101562 2.101563v170.5625c0 1.15625-.941406 2.101562-2.101562 2.101562h-158.832032c-4.144531 0-7.5 3.355469-7.5 7.5s3.355469 7.5 7.5 7.5h158.832032c9.429687 0 17.101562-7.671875 17.101562-17.101562v-170.5625c0-9.429688-7.671875-17.101563-17.101562-17.101563zm-305.132813 58.894531c0 1.160156-.945313 2.101563-2.101563 2.101563h-43.894531v-43.894531c0-1.15625.941407-2.101563 2.101563-2.101563h43.894531zm0 0"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RotateScreen',
  computed: {
    rotateScreen() {
      return this.$store.state.global.settings.rotateScreen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.rotate-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: $backgroundGradient;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__text {
    @include tcl-h2;
    margin-bottom: 20px;
    padding: 0 $containerPadding;
    text-align: center;
  }

  &__icon {
    height: max-content;
    svg {
      width: 100px;
      height: auto;
      fill: $cream;
    }
  }
}
</style>
