const state = {
  appDataName: 'jagermeisterAppData',
  appData: {
    welcome: false,
    cookiesAll: false,
    readQrCodes: [],
    modalQrHelpIsShow: false,
  },
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  saveAppData(state, payload) {
    state.appData[payload.key] = payload.value;
    localStorage.setItem(state.appDataName, JSON.stringify(state.appData));
  },
  loadAppData(state) {
    const jagermeisterData = localStorage.getItem(state.appDataName);
    if (!jagermeisterData) return;

    state.appData = JSON.parse(jagermeisterData);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
