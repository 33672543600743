import { createRouter, createWebHistory } from 'vue-router';
import Page from '@/views/Page';
import Page404 from '@/views/Page404';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Page,
  },
  { path: '/:pathMatch(.*)*', name: 'page404', component: Page404 },
  // { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
