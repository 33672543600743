<template>
  <ModalWindow :show="show" @closeModal="$emit('close-modal')">
    <div class="modal">
      <div class="modal__heading" v-if="modalQrHelp.heading">
        <h2>{{ modalQrHelp.heading }}</h2>
      </div>
      <div
        class="modal__description"
        v-if="modalQrHelp.description"
        v-html="modalQrHelp.description"
      ></div>
      <div class="modal__steps-images">
        <img class="lock" src="@/assets/images/lock.svg" alt="Lock" />
        <img class="arrow" src="@/assets/images/arrow.svg" alt="Arrow" />
        <img
          @click="$emit('close-modal')"
          class="qrcode"
          src="@/assets/images/qrcode.svg"
          alt="qrcode"
        />
        <img class="arrow" src="@/assets/images/arrow.svg" alt="Arrow" />
        <img class="unlock" src="@/assets/images/unlock.svg" alt="Unlock" />
      </div>
    </div>
  </ModalWindow>
</template>

<script>
import ModalWindow from '@/components/ModalWindow';

export default {
  name: 'ModalQrCheck',
  components: { ModalWindow },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    modalQrHelp() {
      return this.$store.state.global.settings.modalQrHelp;
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.modal {
  &__heading {
    @include tcl-h2;
    @include tcl-side-line;
    margin-bottom: 30px;
    & > * {
      position: relative;
      z-index: 1;
    }
  }
  &__description {
    @include tcl-big-text;
    margin-bottom: 30px;
  }
  &__steps-images {
    display: flex;
    align-items: center;
    justify-content: center;
    .lock,
    .unlock {
      width: 60px;
      height: auto;
    }
    .arrow {
      width: 30px;
      height: auto;
      margin: 0 10px;
    }
    .qrcode {
      width: 100px;
      height: auto;
    }
  }
}
</style>
