import CustomPostTypes from '@/services/CustomPostTypes';

const state = {
  festivals: [],
  drinks: [],
  singleFestival: {},
  singleDrink: {},
};

// getters
const getters = {};

// actions
const actions = {
  fetchFestivals({ commit }) {
    CustomPostTypes.getPostType('festivals')
      .then(({ data }) => {
        commit('setFestivals', data);
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
  fetchSingleFestival({ commit }, id) {
    CustomPostTypes.getSinglePost('festivals', id)
      .then(({ data }) => {
        commit('setSingleFestival', data);
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
  fetchDrinks({ commit }) {
    CustomPostTypes.getPostType('drinks')
      .then(({ data }) => {
        commit('setDrinks', data);
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
  fetchSingleDrink({ commit }, id) {
    CustomPostTypes.getSinglePost('drinks', id)
      .then(({ data }) => {
        commit('setSingleDrink', data);
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
};

// mutations
const mutations = {
  setFestivals(state, payload) {
    state.festivals = payload;
  },
  setSingleFestival(state, payload) {
    state.singleFestival = payload;
  },
  setDrinks(state, payload) {
    state.drinks = payload;
  },
  setSingleDrink(state, payload) {
    state.singleDrink = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
