<template lang="html">
  <section
    class="section-item"
    :class="`section-item--${transformComponentName} section-item--margin-${section['bottom_margin']}`"
  >
    <component :is="componentLoader" :initialData="section"></component>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'SectionsItem',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    transformComponentName() {
      const delimiter = '_';
      const str = this.section.acf_fc_layout;
      const regex = new RegExp(delimiter + '([a-z])', 'g');
      const componentName = str.replace(regex, function (g) {
        return g[1].toUpperCase();
      });

      return componentName[0].toUpperCase() + componentName.slice(1);
    },
    componentLoader() {
      return defineAsyncComponent(() =>
        import(`@/sections/${this.transformComponentName}.vue`)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.section-item {
  &--margin {
    &-none {
      margin-bottom: 0;
    }
    &-small {
      margin-bottom: $sectionSmallMargin;
    }
    &-medium {
      margin-bottom: $sectionMediumMargin;
    }
    &-large {
      margin-bottom: $sectionLargeMargin;
    }
  }
  &--AudioCall {
    margin: 0;
    position: fixed;
    left: 0;
    top: $headerHeight;
    width: 100%;
    height: calc(100% - #{$headerHeight});
    background: $backgroundGradient;
  }
}
</style>
