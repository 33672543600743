<template>
  <ModalWindow :show="show" @closeModal="closeModal">
    <div class="modal">
      <div class="modal__heading" v-if="modalData.heading">
        <h2>{{ modalData.heading }}</h2>
      </div>
      <div
        class="modal__description"
        v-if="modalData.description"
        v-html="modalData.description"
      ></div>
      <div class="modal__button" v-if="modalData.button">
        <button type="button" @click="buttonClick">
          {{ modalData.button }}
        </button>
      </div>
    </div>
  </ModalWindow>
</template>

<script>
import ModalWindow from '@/components/ModalWindow';

export default {
  name: 'ModalQrFailure',
  components: { ModalWindow },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    modalData() {
      return this.$store.state.global.settings.modalFailureQrCode;
    },
    siteMap() {
      return this.$store.state.global.siteMap;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    buttonClick() {
      this.closeModal();
      this.$store.commit('routing/setCurrentRoute', this.siteMap.home);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.modal {
  &__heading {
    @include tcl-h2;
    @include tcl-side-line;
    margin-bottom: 30px;
    & > * {
      position: relative;
      z-index: 1;
    }
  }
  &__description {
    @include tcl-big-text;
    margin-bottom: 40px;
  }
  &__button {
    button {
      @include tcl-button;
      width: max-content;
      margin: 0 auto;
    }
  }
}
</style>
