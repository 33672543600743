<template lang="html">
  <ModalWindow :show="show" @closeModal="closeModal">
    <div class="qrcode-wrapper">
      <div class="qrcode-wrapper__content">
        <Loader v-if="!cameraIsLoaded" />
        <p class="error">{{ error }}</p>
      </div>
      <StreamBarcodeReader
        @loaded="onInit"
        @decode="onDecode"
        @error="errorScrenner"
      />
    </div>
  </ModalWindow>
</template>

<script>
import ModalWindow from '@/components/ModalWindow';
import Loader from '@/components/Loader';
import StreamBarcodeReader from '@/components/StreamBarcodeReader';

export default {
  name: 'QrCodeCamera',
  components: { StreamBarcodeReader, Loader, ModalWindow },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: '',
      cameraIsLoaded: false,
    };
  },
  methods: {
    onDecode(result) {
      if (result) {
        const strToArr = result.split('/');
        this.$emit('read-code', strToArr[strToArr.length - 1]);
      }
    },
    onInit() {
      this.cameraIsLoaded = true;
    },
    closeModal() {
      this.$emit('close-modal');
      this.cameraIsLoaded = false;
      this.error = '';
    },
    errorScrenner(e) {
      this.error = e;
      this.cameraIsLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  font-weight: bold;
  color: red;
}
.qrcode-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  :deep(.scanner-container) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    :deep(svg) {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
