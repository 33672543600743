<template lang="html">
  <div class="heading" v-if="heading" :class="`heading--${headingType}`">
    <component :is="headingType">{{ heading }}</component>
  </div>
</template>

<script>
export default {
  name: 'Heading',
  props: {
    headingType: {
      type: String,
      required: false,
      default: 'h2',
    },
    heading: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.heading {
  @include tcl-side-line;
  text-align: left;
  text-transform: uppercase;

  h1 {
    position: relative;
    z-index: 1;
    @include tcl-h1;
  }

  h2 {
    position: relative;
    z-index: 1;
    @include tcl-h2;
  }

  h3 {
    position: relative;
    z-index: 1;
    @include tcl-h3;
  }

  &--h1 {
    &:before {
      height: 25px;
      bottom: 0;
    }
  }
}
</style>
