<template lang="html">
  <transition name="fade">
    <div class="modal-window" v-if="show">
      <div class="modal-window__container" v-click-outside="onClickOutside">
        <div class="modal-window__close">
          <img
            @click="closeModal"
            src="@/assets/images/close.svg"
            alt="Close"
          />
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'ModalWindow',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClickOutside() {
      this.closeModal();
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.modal-window {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($lightGray, 0.7);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &__container {
    padding: 40px;
    width: 100%;
    max-height: 100%;
    background: $backgroundGradient;
    position: relative;
  }

  &__close {
    img {
      fill: $white;
      width: 20px;
      height: auto;
      position: absolute;
      right: 10px;
      top: -10px;
    }
  }
}

.fade-enter-from {
  opacity: 0;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
  transition: opacity 0.4s;
}

.fade-enter-to {
  opacity: 1;
  transition: opacity 0.4s;
}
</style>
