<template lang="html">
  <div class="page-404">
    <div class="page-404__logo">
      <span>4</span>
      <img
        v-if="page404.logo"
        :src="page404.logo.sizes['medium_large']"
        :alt="page404.logo.alt"
      />
      <span>4</span>
    </div>
    <div class="page-404__audio" v-if="this.page404.audio">
      <svg
        @click="playAudio"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25" cy="25" r="25" fill="#E9E3C7" />
        <path
          d="M18.7687 13.1428C18.102 12.7579 17.2687 13.239 17.2687 14.0088L17.2687 35.8267C17.2687 36.5965 18.102 37.0776 18.7687 36.6927L37.6635 25.7838C38.3302 25.3989 38.3302 24.4366 37.6635 24.0517L18.7687 13.1428Z"
          fill="#193B01"
        />
      </svg>
    </div>
    <HeadingDescription
      v-if="page404.heading || page404.description"
      :initialData="{
        heading: page404.heading,
        description: page404.description,
        text_align: {
          value: 'left',
        },
        heading_type: {
          value: 'h2',
        },
      }"
    />
    <div class="page-404__button" v-if="page404.button">
      <button @click="goToHome">{{ page404.button }}</button>
    </div>
  </div>
</template>

<script>
import HeadingDescription from '@/sections/HeadingDescription';
export default {
  name: 'Page404',
  components: { HeadingDescription },
  data() {
    return {
      audioPlaying: false,
      audio: null,
    };
  },
  computed: {
    page404() {
      return this.$store.state.global.settings.page404;
    },
    siteMap() {
      return this.$store.state.global.siteMap;
    },
  },
  beforeUnmount() {
    this.stopAudio();
    this.audio = null;
  },

  methods: {
    goToHome() {
      this.$router.push({ name: 'home' });
      this.$store.commit('routing/setCurrentRoute', this.siteMap.home);
    },
    playAudio() {
      if (!this.page404.audio) return;
      else if (this.audioPlaying) return;
      this.audio = new Audio(this.page404.audio.url);
      this.audio.play();
      this.audioPlaying = true;
    },
    stopAudio() {
      this.audio.pause();
      this.audio.currentTime = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.page-404 {
  &__logo {
    display: flex;
    position: relative;
    margin: 50px auto 20px;
    width: max-content;

    span {
      font-size: 160px;
      line-height: 1;
      font-weight: bold;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:first-child {
        left: -90px;
      }
      &:last-child {
        right: -90px;
      }
    }
    img {
      width: 148px;
      height: 148px;
      position: relative;
      z-index: 1;
    }
  }
  &__button {
    width: 90%;
    margin: 50px auto 50px;

    button {
      @include tcl-button;
    }
  }
  &__audio {
    width: max-content;
    margin: 0 auto 40px;
    position: relative;

    svg {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 110px;
      height: 15px;
      background-color: $orange;
    }

    svg {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
